import "./sidebar.css";
import logoBack from "./icons/back.svg";
import logoHome from "./icons/home.svg";
import logoPedidos from "./icons/pedidos.svg";

const Sidebar = () => {
  return (
    <div className="side-bar">
      <div className="header">
        <h2>CLUB GASCO</h2>
        <p>Vuelve al sitio principal</p>
      </div>
      <div className="back">
        <img src={logoBack}></img>
        <p>Volver al home</p>
      </div>
      <ul>
        <li className="item">
          <img src={logoPedidos}></img>
          <a href="?path=pedidos">pedidos</a>
        </li>

        <li className="item">
          <img src={logoHome} />
          <a href="?path=productos">microfront2</a>
        </li>
      </ul>
    </div>
  );
};

export default function Root(props) {
  return <Sidebar />;
}
